import React from 'react';

function CreditPurchaseModal({ show, onClose, onSelectPlan }) {
  const plans = [
    { id: 'basic', credits: 10, price: 5 },
    { id: 'standard', credits: 60, price: 25 },
    { id: 'premium', credits: 130, price: 50 },
  ];

  return (
    <div
      className={`modal fade ${show ? 'show' : ''}`}
      style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      tabIndex="-1"
      role="dialog"
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '800px' }}>
        <div className="modal-content border-0 shadow-lg">
          <div className="modal-header border-0 p-4">
            <h5 className="modal-title font-weight-bold" style={{ fontSize: '1.5rem' }}>Buy Credits</h5>
          </div>

          <div className="modal-body pt-0 pb-4 px-4">
            <p className="mb-4" style={{ fontSize: '1.1rem', color: '#555' }}>
              Select a credit package to purchase:
            </p>
            <div className="row">
              {plans.map((plan) => (
                <div key={plan.id} className="col-md-4 mb-4">
                  <div
                    className="card h-100 border-0 shadow-sm"
                    style={{
                      borderRadius: '8px',
                      transition: 'transform 0.2s ease-in-out',
                      cursor: 'pointer',
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.transform = 'translateY(-3px)')}
                    onMouseOut={(e) => (e.currentTarget.style.transform = 'translateY(0)')}
                  >
                    <div className="card-body text-center d-flex flex-column justify-content-center">
                      <h6 className="card-title font-weight-bold mb-2" style={{ fontSize: '1.25rem' }}>
                        {plan.credits} Credits
                      </h6>
                      <p className="card-text mb-4" style={{ fontSize: '1rem', color: '#777' }}>
                        ${plan.price}
                      </p>
                      <button
                        className="btn btn-primary"
                        style={{ borderRadius: '4px' }}
                        onClick={() => onSelectPlan(plan.credits)}
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="modal-footer border-0 p-4">
            <button
              type="button"
              className="btn btn-secondary"
              style={{ borderRadius: '4px' }}
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditPurchaseModal;
